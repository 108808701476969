/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'
import {PrivateRoutes, PrivateRoutesMember} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {LoginWrapper} from '../modules/frontend/login-member/login/Login'
import LoginPage from '../modules/frontend/login-member/LoginPage'
import TieCreditPage from '../modules/frontend/tiecredit/TieCreditPage'
import LoginLinePage from '../modules/frontend/login-line/LoginLinePage'
import RegisterPage from '../modules/frontend/register-member/RegisterPage'
import {RegisterWrapper} from '../modules/frontend/register-member/register/Register'
import AlertLoginPage from '../modules/frontend/alert-login/AlertLoginPage'
import MemberCancelAccountPage from '../modules/frontend/member-cancel-account/MemberCancelAccountPage'
import {App} from '../App'
import axios from 'axios'
import liff from '@line/liff';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const {saveAuth, setCurrentUser} = useAuth()
  const check_login = localStorage.getItem('kt-auth-react-v')
  const check_auth_session = JSON.parse(""+check_login)

  const {currentMember}:any = useAuth()
  const {saveSetAuthMember, setCurrentMember, logoutMember} = useAuth()
  const {otpFirstLogin, setOtpFirstLogin} = useAuth()
  const {dataBeforeSaveMember, setDataBeforeSaveMember} = useAuth()
  const check_login_member:any = localStorage.getItem('kt-auth-member-react-v')
  const check_auth_member_session = JSON.parse(""+check_login_member)
  if(dataBeforeSaveMember){
    var dataBeforeSaveMemberCheck:any = dataBeforeSaveMember;
     console.log('dataBeforeSaveMember', dataBeforeSaveMemberCheck.otp_first_login);
  }



  console.log('check_auth_member_session', check_auth_member_session);
  console.log('currentMember', currentMember);



  console.log('otpFirstLogin', otpFirstLogin);
    if(!currentUser && check_auth_session && check_login){
      saveAuth(check_auth_session)
      setCurrentUser(check_auth_session)
    }

    if(!currentMember && check_auth_member_session && check_login_member){
      saveSetAuthMember(check_login_member)
      setCurrentMember(check_auth_member_session)
    }
  // // const location2 = useLocation();
  const checkURL:any = window.location.pathname
  // console.log('URL', checkURL.search("apps"));
  const checkURLAdmin:any = checkURL.search("apps");
  const checkURLMember:any = checkURL.search("frontend");
  const checkURLAuthAdmin:any = checkURL.search("auth");
  const checkLoginMemberInRoute:any = (currentMember) ? currentMember : dataBeforeSaveMember;
  const lineUserID = window.sessionStorage.getItem("lineUserID");
  // console.log('route lineUserID', lineUserID);
  if(check_auth_member_session || dataBeforeSaveMemberCheck){
    var data_check_member_first_login:any = (check_auth_member_session) ? check_auth_member_session.otp_first_login : dataBeforeSaveMemberCheck.otp_first_login;
    var data_check_member_tie_credit:any = (check_auth_member_session) ? check_auth_member_session.otp_tie_credit : dataBeforeSaveMemberCheck.otp_tie_credit;
  }

  if(currentMember){
    // const test = (currentMember.line_user_login) ? currentMember.line_user_login : null;
    //   if(test){
      liff.init({ liffId: '1657455630-LVDlpvqW' })
      .then(async () => {

        if(liff.isLoggedIn()){
          await axios.get("https://asia-southeast1-mkt-lineoa.cloudfunctions.net/graphql/api/getLogLogin?line_user_id=" + currentMember.line_user_login + "&line_token=" + liff.getIDToken())
          .then(async function (res)  {
            console.log('PrivateRoutesMember', res.data.data_logged);
            if(res.data.data_logged === null){
              logoutMember();
              liff.logout();
              // navigate("/frontend/login-line/login-line");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }

      })
  .catch(err=>{console.log(err)})
      // }
  }

  // if(data_check_member_first_login == true ){
  //   saveSetAuthMember(dataBeforeSaveMemberCheck)
  //   setCurrentMember(dataBeforeSaveMemberCheck)
  // }
  // console.log('URL', window.location.href);

  // console.log('currentUser',currentUser);
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
            {/* {(currentUser || currentMember) ? ( */}
              {/* <> */}
                {/* <Route path='/*' element={<PrivateRoutes />} /> */}
                {/* <Route index element={<Navigate to='/dashboard' />} /> */}
              {/* </> */}
            {/* ) : ( */}
                  {/* <> */}
                    {/* <Route path='auth/*' element={<AuthPage />} /> */}
                    {/* <Route path='*' element={<Navigate to='/auth' />} /> */}
                    {/* <Route path='/frontend/login/*' element={<Navigate to='/frontend/login/login' />} /> */}
                  {/* </> */}

            {/* )} */}



            {(!currentUser || !currentMember) && (
              // console.log('not')
              <>
                <Route path='/' element={<LoginLinePage />} />
                {/* <Route path='/frontend/' element={<Navigate to='frontend/' />} /> */}
              </>
            )}

            {/* {(currentUser || currentMember) && */}
                  {
                    ((currentUser && checkURLAdmin == 1) || currentUser) ? ( //checkURLAuthAdmin == 1
                      // console.log(1)
                      <>
                        <Route path='/*' element={<PrivateRoutes />} />
                        <Route index element={<Navigate to='/apps/user-management/users' />} />
                      </>
                    ) : (
                      // console.log(2)
                          <>
                            <Route path='auth/*' element={<AuthPage />} />
                            <Route path='apps/*' element={<Navigate to='/auth' />} />
                            {/* <Route path='dashboard' element={<Navigate to='/auth' />} /> */}
                            {/* <Route path='/frontend/login/*' element={<Navigate to='/frontend/login/login' />} /> */}
                          </>

                    )
                  }

                  {
                    (checkLoginMemberInRoute && checkURLMember == 1 ) || checkLoginMemberInRoute   ? (
                      <>
                      {/* {console.log('เข้าอีกแล้ว55')} */}
                      {/* {(data_check_member_first_login  && (
                        <>
                          <Route path='/frontend/otpsms/otpsms' element={<Navigate to='/frontend/tiecredit/tiecredit' />} />
                        </>
                      ))} */}

                      {(data_check_member_tie_credit  && (
                          <>
                          <Route path='/frontend/otpsms-for-tiecredit/otpsms-for-tiecredit' element={<Navigate to='/frontend/menu-member/menu-member' />} />
                          <Route path='/frontend/tiecredit/tiecredit' element={<Navigate to='/frontend/menu-member/menu-member' />} />
                          </>
                      ))}


                        <Route path='/*' element={<PrivateRoutesMember />} />
                        <Route index element={<Navigate to='/frontend/menu-member/menu-member' />} />
                        <Route path='/frontend/login-line/login-line' element={<Navigate to='/frontend/menu-member/menu-member' />} />
                      </>
                    ) : (
                            <>
                            {/* {console.log('เข้าอีกแล้ว')} */}



                                  <Route path='/frontend/login-line/*' element={<LoginLinePage />} />
                                  <Route path='/frontend/*' element={<Navigate to='/frontend/login-line/login-line' />}  />
                                  <Route path='/alert-login' element={<AlertLoginPage />}  />
                                  <Route path='/member-cancel-account' element={<MemberCancelAccountPage />}  />

                                  {/* <Route path='/frontend/login/login' element={<Navigate to='/frontend/login-line/login-line' />}  />
                                  <Route path='/frontend/register/register' element={<Navigate to='/frontend/login-line/login-line' />}  /> */}

                                      {/* <Route path='/frontend/login/*' element={<LoginLinePage />} /> */}
                                      <Route path='/frontend/register/register' element={<RegisterWrapper />} />



                              {/* <Route  element={<RegisterPage />} /> */}

                            </>

                    )
                  }



            {/* } */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
