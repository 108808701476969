// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "@firebase/firestore";
// import {getFirestore AS Firestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBNCN2f_w5eSyqaf6Rqns_RkJPzpzAepg",
  authDomain: "mkt-lineoa.firebaseapp.com",
  databaseURL: "https://mkt-lineoa-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mkt-lineoa",
  storageBucket: "mkt-lineoa.appspot.com",
  messagingSenderId: "1070727631522",
  appId: "1:1070727631522:web:2436ac45173ae3a131fdfb",
  measurementId: "G-LV7B2J88GX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseDB =  getFirestore(app);
// const db =  getFirestore(app);
export {firebaseDB}