import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
// import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {initialRegisterMember, RegisterMember} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useListView as useListViewLogin}  from '../../login-member/core/ListViewProvider'
import {RegisterLoading} from '../components/loading/RegisterLoading'
import {createMember} from '../core/_requests'
// import {useQueryResponse} from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import {useConsentList} from '../core/ConsentList'
import { useNavigate } from "react-router-dom";





// type Props = {
//   isRegisterLoading: boolean
//   registerMember: RegisterMember
// }
// const test555 = {testname: Yup.string().required('First name is required')};
// const register_member = Yup.object().shape({
//     ...useListView().consentFromDB,
//     title_name: Yup.string()
//         .required('Plase select title name.'),
//     firstname: Yup.string()
//         .min(3, 'Minimum 3 symbols')
//         .max(50, 'Maximum 50 symbols')
//         .required('Firstname is required'),
//     lastname: Yup.string()
//         .min(3, 'Minimum 3 symbols')
//         .max(50, 'Maximum 50 symbols')
//         .required('Lastname is required'),
//     phone: Yup.string()
//         .matches(
//         /^[0-9]+$/,
//         "Must be numbers only and 10 digit"
//         )
//         .min(10, 'Minimum 10 symbols')
//         .max(10, 'Maximum 10 symbols')
//         .required('Phone is required'),
//     id_card: Yup.string()
//         .matches(
//         /^(?=.*[0-9])(?=.{13,})/,
//         "Must be numbers only and 13 digit"
//         )
//         .min(13, 'Minimum 13 symbols')
//         .max(13, 'Maximum 13 symbols')
//         .required('ID Card is required'),
//     day_of_birth: Yup.string()
//         .required('Day of birth is required'),
//     month_of_birth: Yup.string()
//         .required('Month of birth is required'),
//     year_of_birth: Yup.string()
//         .required('Year of birth is required'),
//     email: Yup.string()
//         .email('Wrong email format')
//         .min(3, 'Minimum 3 symbols')
//         .max(50, 'Maximum 50 symbols')
//         .required('Email is required'),
//     get_news: Yup.array()
//     .min(1, "Pelase selected at least 1")
//     .required("required"),
//     // consent: Yup.array()
//     // .min(1, "Pelase selected at least 1")
//     // .required("required"),
//     // consent: Yup.array()
//     // .of(
//     //   Yup.object().shape({
//     //     consent_code: Yup.string(),
//     //     checked: Yup.boolean(),
//     //   })
//     // )
//     // // .compact((v) => !v.checked)
//     // .min(1, "Pelase selected at least 1")
//     // .required('required-field'),
//     // consent: Yup.array()
//     // .of(
//     //   Yup.object().shape({
//     //     consent_code: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
//     //   })
//     // ),
//     consent: Yup
//     .bool()
//     .oneOf([true, false], 'You need to accept the terms and conditions'),
//     // consent01: Yup
//     // .bool()
//     // .oneOf([true, false], 'You need to accept the terms and conditions'),
//     // consent02: Yup
//     // .bool()
//     // .oneOf([true, false], 'You need to accept the terms and conditions'),
// //   role: Yup.string()
// //     .min(3, 'Minimum 3 symbols')
// //     .max(50, 'Maximum 50 symbols')
// //     .required('Role is required'),
// //   password: Yup.string()
// //     // .min(3, 'Minimum 3 symbols')
// //     // .max(50, 'Maximum 50 symbols')
// //     .matches(
// //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
// //       "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
// //     )
// //     .required('Password is required'),
// })

const RegisterFormContent =  () => {
    // const [consentFromDB]:any = useState({})
    // const consentCheck = useListView().consentCheck;
    const {consentFromDB, setConsentFromDB} = useListView()
    const {lineUserId}:any = useListViewLogin();
    const arr_consent:any = {};
    consentFromDB.forEach((val:any) => {
        arr_consent[val.consent_code] = val.value;
        // console.log(val);
    })
    console.log('lineUserId', lineUserId);
    const navigate = useNavigate();
    const register_member:any = Yup.object().shape({
        title_name: Yup.string()
            .required('กรุณาเลือกคำนำหน้าชื่อ'),
            // .when('title_name_other', {
            //     is: true,
            //     then: Yup.string().required('Field is required')ม
            //     otherwise: yup.string()
            //   }),
        // title_name_other: Yup.string()
        //       .required('Title name other is required'),
        title_name_other: Yup.string().when("title_name", {
            is: (value:any) => value && value == 'อื่นๆ',
            then: Yup.string()
                .required(
                    "กรุณากรอกคำนำหน้าชื่อ"
                ),
            otherwise: Yup.string()
        }),
        firstname: Yup.string()
            .min(3, 'ตัวอักษรต้องมากกว่า 3 ตัวอักษร')
            .max(50, 'ตัวอักษรต้องไม่เกิน 50 ตัวอักษร')
            .required('กรุณากรอกชื่อ'),
        lastname: Yup.string()
            .min(3, 'ตัวอักษรต้องมากกว่า 3 ตัวอักษร')
            .max(50, 'ตัวอักษรต้องไม่เกิน 50 ตัวอักษร')
            .required('กรุณากรอกนามสกุล'),
        phone: Yup.string()
            .matches(
            /^[0-9]+$/,
            "กรุณากรอกเบอร์โทรศัพท์ 10 หลัก"
            )
            .min(10, 'กรุณากรอกเบอร์โทรศัพท์ 10 หลัก')
            .max(10, 'กรุณากรอกเบอร์โทรศัพท์ 10 หลัก')
            .required('กรุณากรอกเบอร์โทรศัพท์ 10 หลัก'),
        id_card: Yup.string()
            .matches(
            /^(?=.*[0-9])(?=.{13,})/,
            "กรุณากรอกหมายเลขบัตรประชาชน 13 หลัก"
            )
            .min(13, 'กรุณากรอกหมายเลขบัตรประชาชน 13 หลัก')
            .max(13, 'กรุณากรอกหมายเลขบัตรประชาชน 13 หลัก')
            .required('กรุณากรอกหมายเลขบัตรประชาชน 13 หลัก'),
        day_of_birth: Yup.string()
            .required('กรุณากรอก วันเดือนปีเกิด'),
        month_of_birth: Yup.string()
            .required('กรุณากรอก วันเดือนปีเกิด'),
        year_of_birth: Yup.string()
            .required('กรุณากรอก วันเดือนปีเกิด'),
        email: Yup.string()
            .email('กรุณากรอกอีเมลให้ถูกต้อง')
            .min(3, 'ตัวอักษรต้องมากกว่า 3 ตัวอักษร')
            .max(50, 'ตัวอักษรต้องไม่เกิน 50 ตัวอักษร')
            .required('กรุณากรอกอีเมลให้ถูกต้อง'),
        // get_news: Yup.array()
        // .min(1, "Pelase selected at least 1")
        // .required("required"),
        // consent: Yup.array()
        // .min(1, "Pelase selected at least 1")
        // .required("required"),
        // consent: Yup.array()
        // .of(
        //   Yup.object().shape({
        //     consent_code: Yup.string(),
        //     checked: Yup.boolean(),
        //   })
        // )
        // // .compact((v) => !v.checked)
        // .min(1, "Pelase selected at least 1")
        // .required('required-field'),
        // consent: Yup.array()
        // .of(
        //   Yup.object().shape({
        //     consent_code: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
        //   })
        // ),
        // consent: Yup
        // .bool()
        // .oneOf([true], 'You need to accept the terms and conditions'),
        // consent01: Yup
        // .bool()
        // .oneOf([true, false], 'You need to accept the terms and conditions'),
        // consent02: Yup
        // .bool()
        // .oneOf([true, false], 'You need to accept the terms and conditions'),
    //   role: Yup.string()
    //     .min(3, 'Minimum 3 symbols')
    //     .max(50, 'Maximum 50 symbols')
    //     .required('Role is required'),
    //   password: Yup.string()
    //     // .min(3, 'Minimum 3 symbols')
    //     // .max(50, 'Maximum 50 symbols')
    //     .matches(
    //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //       "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    //     )
    //     .required('Password is required'),
    })

    // console.log('Sadsafsa',useListView().consentCheck);
    const [registerMemberInsert]:any = useState({
        // ...consentCheck,
        title_name: '',
        title_name_other: '',
        // avatar: user.avatar || initialUser.avatar,
        // role: admin.role || initialAdmin.role,
        phone: '',
        id_card: '',
        firstname: '',
        nickname: '',
        lastname: '',
        email: '',
        day_of_birth: '',
        month_of_birth: '',
        year_of_birth: '',
        get_news: (arr_consent['consent02'] === true) ? ['email'] :[],

        // consent:null,
      })

    const {setItemIdForUpdate} = useListView()
    const {consentRequired} = useListView()
    const data_consent = useConsentList()




  const formik = useFormik({
    initialValues: registerMemberInsert,
    validationSchema: register_member,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('scaascas',values);
      if(values['title_name'] == 'อื่นๆ'){
        values['title_name']  = values['title_name_other']
      }
      values['arr_consent'] = arr_consent;
      const lineUserID:any = window.sessionStorage.getItem("lineUserID");
      values['line_user_login'] = lineUserID.slice(1, -1)

      console.log('formik values',values);
      setSubmitting(true)
      try {

        createMember(values);
        // if (isNotEmpty(values.id)) {
        //   await updateAdmin(values)
          Swal.fire({
            icon: 'success',
            title: 'Register Success.',
            showConfirmButton: false,
            timer: 1500
          })

          navigate("/frontend/tiecredit/tiecredit");
        // // } else {
        //   await createAdmin(values)
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Insert Admin',
        //     showConfirmButton: false,
        //     timer: 1500
        //   })
        // }
      } catch (ex) {
        console.error(ex)
      } finally {
        sessionStorage.removeItem('lineUserID')
        setSubmitting(true)
      }
    },
  })

  return (
    <>

                <form id='form_register_member' className='form'
                    onSubmit={formik.handleSubmit}
                    noValidate>
                    {/* begin::Scroll */}
                    <div
                        className='d-flex flex-column scroll-y me-n7 pe-7'
                        id='form_register_member_div'
                        // data-kt-scroll='true'
                        // data-kt-scroll-activate='{default: false, lg: true}'
                        // data-kt-scroll-max-height='auto'
                        //   data-kt-scroll-dependencies='#kt_modal_add_admin_header'
                        //   data-kt-scroll-wrappers='#form_register_member_div'
                        //   data-kt-scroll-offset='300px'
                    >
                        {/* {console.log(formik.getFieldProps('title_name'))} */}
                    <div className='fv-row mb-7'>
                        <label className='required fw-bold fs-6 mb-2'>คำนำหน้าชื่อ</label>
                            <div>
                                <span className='form-check form-check-custom form-check-solid'>
                                    <input type="radio"  {...formik.getFieldProps('title_name')} className="form-check-input" value="นาย" name="title_name" />
                                    <label className="p-2">นาย</label>
                                    <input type="radio" {...formik.getFieldProps('title_name')} className="form-check-input" value="นาง" name="title_name" />
                                    <label className="p-2">นาง</label>
                                    <input type="radio" {...formik.getFieldProps('title_name')} className="form-check-input" value="นางสาว" name="title_name" />
                                    <label className="p-2">นางสาว</label>
                                    <input type="radio"
                                    {...formik.getFieldProps('title_name')}
                                    className="form-check-input" value="อื่นๆ" name="title_name" />
                                    <label className="p-2">อื่นๆ</label>
                                    <div className="col-4">
                                        <input type="text" {...formik.getFieldProps('title_name_other')}  className="form-control form-control-solid mb-3 mb-lg-0"  name="title_name_other" />
                                    </div>
                                </span>
                                {formik.errors.title_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                        <span role='alert' className="text-danger">{formik.errors.title_name}</span>
                                        </div>
                                    </div>
                                )}
                                {formik.errors.title_name_other && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                        <span role='alert' className="text-danger">{formik.errors.title_name_other}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                    </div>

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>ชื่อ</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                        placeholder='ชื่อ'
                          {...formik.getFieldProps('firstname')}
                        type='text'
                        name='firstname'
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.firstname && formik.errors.firstname},
                            {
                              'is-valid': formik.touched.firstname && !formik.errors.firstname,
                            }
                        )}
                        autoComplete='off'
                          disabled={formik.isSubmitting }
                        />
                        {formik.touched.firstname && formik.errors.firstname && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert' className="text-danger">{formik.errors.firstname}</span>
                            </div>
                        </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                     {/* begin::Input group */}
                     <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>นามสกุล</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                        placeholder='นามสกุล'
                          {...formik.getFieldProps('lastname')}
                        type='text'
                        name='lastname'
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.lastname && formik.errors.lastname},
                            {
                              'is-valid': formik.touched.lastname && !formik.errors.lastname,
                            }
                        )}
                        autoComplete='off'
                          disabled={formik.isSubmitting}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert' className="text-danger">{formik.errors.lastname}</span>
                            </div>
                        </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>ชื่อกลาง</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                        placeholder='ชื่อกลาง'
                          {...formik.getFieldProps('nickname')}
                        type='text'
                        name='nickname'
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.nickname && formik.errors.nickname},
                            {
                              'is-valid': formik.touched.nickname && !formik.errors.nickname,
                            }
                        )}
                        autoComplete='off'
                          disabled={formik.isSubmitting }
                        />
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>เลขบัตรประชาชน</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                        placeholder='เลขบัตรประชาชน'
                          {...formik.getFieldProps('id_card')}
                        type='text'
                        name='id_card'
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.id_card && formik.errors.id_card},
                            {
                              'is-valid': formik.touched.id_card && !formik.errors.id_card,
                            }
                        )}
                        autoComplete='off'
                        //   disabled={formik.isSubmitting || isAdminLoading}
                        />
                        {formik.touched.id_card && formik.errors.id_card && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert' className="text-danger">{formik.errors.id_card}</span>
                            </div>
                        </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>เบอร์โทรศัพท์</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                        placeholder='เบอร์โทรศัพท์'
                        {...formik.getFieldProps('phone')}
                        type='text'
                        name='phone'
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.phone && formik.errors.phone},
                            {
                              'is-valid': formik.touched.phone && !formik.errors.phone,
                            }
                        )}
                        autoComplete='off'
                          disabled={formik.isSubmitting}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert' className="text-danger">{formik.errors.phone}</span>
                            </div>
                        </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>วัน/เดือน/ปีเกิด</label>
                        {/* end::Label */}
                        <div className="row">
                            <div className="col-4">
                                {/* begin::Input */}
                                <select {...formik.getFieldProps('day_of_birth')}   className="form-select form-select-solid form-select-lg fw-bold">
                                    <option value="">เลือกวันเกิด</option>
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                                {formik.touched.day_of_birth && formik.errors.day_of_birth && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                    <span role='alert' className="text-danger">{formik.errors.day_of_birth}</span>
                                    </div>
                                </div>
                                )}
                                {/* end::Input */}
                            </div>
                            <div className="col-4">
                                {/* begin::Input */}
                                {/* <input
                                    placeholder='เดือนเกิด'
                                    //   {...formik.getFieldProps('firstname')}
                                    type='text'
                                    name='monthBirth'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        // {'is-invalid': formik.touched.firstname && formik.errors.firstname},
                                        // {
                                        //   'is-valid': formik.touched.firstname && !formik.errors.firstname,
                                        // }
                                    )}
                                    autoComplete='off'
                                    //   disabled={formik.isSubmitting || isAdminLoading}
                                /> */}
                                <select {...formik.getFieldProps('month_of_birth')} className="form-select form-select-solid form-select-lg fw-bold">
                                    <option value="">เลือกเดือนเกิด</option>
                                    <option value="01">01</option>
                                    <option value="02">02</option>
                                    <option value="03">03</option>
                                    <option value="04">04</option>
                                    <option value="05">05</option>
                                    <option value="06">06</option>
                                    <option value="07">07</option>
                                    <option value="07">07</option>
                                    <option value="08">08</option>
                                    <option value="09">09</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                {formik.touched.month_of_birth && formik.errors.month_of_birth && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                    <span role='alert' className="text-danger">{formik.errors.month_of_birth}</span>
                                    </div>
                                </div>
                                )}
                                {/* end::Input */}
                            </div>
                            <div className="col-4">
                                {/* begin::Input */}
                                {/* <input
                                    placeholder='ปีเกิด'
                                    //   {...formik.getFieldProps('firstname')}
                                    type='text'
                                    name='yrarBirth'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        // {'is-invalid': formik.touched.firstname && formik.errors.firstname},
                                        // {
                                        //   'is-valid': formik.touched.firstname && !formik.errors.firstname,
                                        // }
                                    )}
                                    autoComplete='off'
                                    //   disabled={formik.isSubmitting || isAdminLoading}
                                /> */}
                                <select {...formik.getFieldProps('year_of_birth')} className="form-select form-select-solid form-select-lg fw-bold">
                                    <option value=''>เลือกปีเกิด</option>
                                    <option value='2019'>2019</option>
                                    <option value='2018'>2018</option>
                                    <option value='2017'>2017</option>
                                    <option value='2016'>2016</option>
                                    <option value='2015'>2015</option>
                                    <option value='2014'>2014</option>
                                    <option value='2013'>2013</option>
                                    <option value='2012'>2012</option>
                                    <option value='2011'>2011</option>
                                    <option value='2010'>2010</option>
                                    <option value='2009'>2009</option>
                                    <option value='2008'>2008</option>
                                    <option value='2007'>2007</option>
                                    <option value='2006'>2006</option>
                                    <option value='2005'>2005</option>
                                    <option value='2004'>2004</option>
                                    <option value='2003'>2003</option>
                                    <option value='2002'>2002</option>
                                    <option value='2001'>2001</option>
                                    <option value='2000'>2000</option>
                                    <option value='1999'>1999</option>
                                    <option value='1998'>1998</option>
                                    <option value='1997'>1997</option>
                                    <option value='1996'>1996</option>
                                    <option value='1995'>1995</option>
                                    <option value='1994'>1994</option>
                                    <option value='1993'>1993</option>
                                    <option value='1992'>1992</option>
                                    <option value='1991'>1991</option>
                                    <option value='1990'>1990</option>
                                    <option value='1989'>1989</option>
                                    <option value='1988'>1988</option>
                                    <option value='1987'>1987</option>
                                    <option value='1986'>1986</option>
                                    <option value='1985'>1985</option>
                                    <option value='1984'>1984</option>
                                    <option value='1983'>1983</option>
                                    <option value='1982'>1982</option>
                                    <option value='1981'>1981</option>
                                    <option value='1980'>1980</option>
                                    <option value='1979'>1979</option>
                                    <option value='1978'>1978</option>
                                    <option value='1977'>1977</option>
                                    <option value='1976'>1976</option>
                                    <option value='1975'>1975</option>
                                    <option value='1974'>1974</option>
                                    <option value='1973'>1973</option>
                                    <option value='1972'>1972</option>
                                    <option value='1971'>1971</option>
                                    <option value='1970'>1970</option>
                                    <option value='1969'>1969</option>
                                    <option value='1968'>1968</option>
                                    <option value='1967'>1967</option>
                                    <option value='1966'>1966</option>
                                    <option value='1965'>1965</option>
                                    <option value='1964'>1964</option>
                                    <option value='1963'>1963</option>
                                    <option value='1962'>1962</option>
                                    <option value='1961'>1961</option>
                                    <option value='1960'>1960</option>
                                    <option value='1959'>1959</option>
                                    <option value='1958'>1958</option>
                                    <option value='1957'>1957</option>
                                    <option value='1956'>1956</option>
                                    <option value='1955'>1955</option>
                                    <option value='1954'>1954</option>
                                    <option value='1953'>1953</option>
                                    <option value='1952'>1952</option>
                                    <option value='1951'>1951</option>
                                    <option value='1950'>1950</option>
                                    <option value='1949'>1949</option>
                                    <option value='1948'>1948</option>
                                    <option value='1947'>1947</option>
                                    <option value='1946'>1946</option>
                                    <option value='1945'>1945</option>
                                    <option value='1944'>1944</option>
                                    <option value='1943'>1943</option>
                                    <option value='1942'>1942</option>
                                    <option value='1941'>1941</option>
                                    <option value='1940'>1940</option>
                                    <option value='1939'>1939</option>
                                    <option value='1938'>1938</option>
                                    <option value='1937'>1937</option>
                                    <option value='1936'>1936</option>
                                    <option value='1935'>1935</option>
                                    <option value='1934'>1934</option>
                                    <option value='1933'>1933</option>
                                    <option value='1932'>1932</option>
                                    <option value='1931'>1931</option>
                                    <option value='1930'>1930</option>
                                    <option value='1929'>1929</option>
                                    <option value='1928'>1928</option>
                                    <option value='1927'>1927</option>
                                    <option value='1926'>1926</option>
                                    <option value='1925'>1925</option>
                                    <option value='1924'>1924</option>
                                    <option value='1923'>1923</option>
                                    <option value='1922'>1922</option>
                                    <option value='1921'>1921</option>
                                    <option value='1920'>1920</option>
                                    <option value='1919'>1919</option>
                                    <option value='1918'>1918</option>
                                    <option value='1917'>1917</option>
                                    <option value='1916'>1916</option>
                                    <option value='1915'>1915</option>
                                    <option value='1914'>1914</option>
                                    <option value='1913'>1913</option>
                                    <option value='1912'>1912</option>
                                    <option value='1911'>1911</option>
                                    <option value='1910'>1910</option>
                                    <option value='1909'>1909</option>
                                    <option value='1908'>1908</option>
                                    <option value='1907'>1907</option>
                                    <option value='1906'>1906</option>
                                    <option value='1905'>1905</option>
                                    <option value='1904'>1904</option>
                                    <option value='1903'>1903</option>
                                    <option value='1902'>1902</option>
                                    <option value='1901'>1901</option>
                                    <option value='1900'>1900</option>
                                </select>
                                {formik.touched.year_of_birth && formik.errors.year_of_birth && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                    <span role='alert' className="text-danger">{formik.errors.year_of_birth}</span>
                                    </div>
                                </div>
                                )}
                                {/* end::Input */}
                            </div>
                        </div>
                    </div>
                    {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>อีเมล์</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                        placeholder='อีเมล์'
                        {...formik.getFieldProps('email')}
                        type='text'
                        name='email'
                        className={clsx(
                            'form-control form-control-solid mb-3 mb-lg-0',
                            {'is-invalid': formik.touched.email && formik.errors.email},
                            {
                              'is-valid': formik.touched.email && !formik.errors.email,
                            }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting}
                        />
                        {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert' className="text-danger">{formik.errors.email}</span>
                            </div>
                        </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>รับข้อมูลข่าวสาร</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        {/* <input
                            placeholder='อีเมล์'
                            //   {...formik.getFieldProps('firstname')}
                            type='text'
                            name='email'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                // {'is-invalid': formik.touched.firstname && formik.errors.firstname},
                                // {
                                //   'is-valid': formik.touched.firstname && !formik.errors.firstname,
                                // }
                            )}
                            autoComplete='off'
                            //   disabled={formik.isSubmitting || isAdminLoading}
                        /> */}
                        <div className="row">
                            <div className="col-3">
                                <label className='form-check form-check-inline form-check-solid me-5'>
                                    <input
                                    {...formik.getFieldProps('get_news')}
                                    className='form-check-input'
                                    name='get_news'
                                    type='checkbox'
                                    defaultChecked={arr_consent['consent02'] }
                                    // onChange={() => {
                                    //     updateData({
                                    //     communications: {
                                    //         email: !data.communications?.email,
                                    //         phone: data.communications?.phone,
                                    //     },
                                    //     })
                                    // }}
                                    checked={arr_consent['consent02'] }
                                    //disabled={true}
                                    value="email"
                                    />
                                    <span className='fw-bold ps-2 fs-6'>อีเมล์</span>
                                </label>
                            </div>
                            {/* <div className="col-3">
                                <label className='form-check form-check-inline form-check-solid me-5'>
                                    <input
                                    {...formik.getFieldProps('get_news')}
                                    className='form-check-input'
                                    name='get_news'
                                    type='checkbox'
                                    // defaultChecked={data.communications?.email}
                                    // onChange={() => {
                                    //     updateData({
                                    //     communications: {
                                    //         email: !data.communications?.email,
                                    //         phone: data.communications?.phone,
                                    //     },
                                    //     })
                                    // }}
                                    value="line"
                                    />
                                    <span className='fw-bold ps-2 fs-6'>ไลน์</span>
                                </label>
                            </div> */}
                        </div>
                        {formik.touched.get_news && formik.errors.get_news && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                            <span role='alert' className="text-danger">{formik.errors.get_news}</span>
                            </div>
                        </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                </div>
                    {/* end::Scroll */}
                        {/* {console.log('formik.getFieldProps',registerMemberInsert['consent01'])}
                        {console.log('formik.getFieldProps1',formik.isSubmitting)}
                        {console.log('formik.getFieldProps2',formik.isValid )}
                        {console.log('formik.getFieldProps3',formik.touched)}
                        {console.log('formik.getFieldProps4',formik.errors)} */}
                    {/* begin::Actions */}
                    <div className='text-center col-12 pt-15'>
                    {/* <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-admin-modal-action='cancel'
                        disabled={formik.isSubmitting || isAdminLoading}
                    >
                        Discard
                    </button> */}

                        <button
                            type='submit'
                            className='btn btn-primary w-100'
                            // data-kt-admin-modal-action='submit'
                            disabled={formik.isSubmitting   || !formik.touched }
                            // disabled={formik.isSubmitting || (!formik.errors.consent01 && registerMemberInsert['consent01'] !== false) }
                        >
                            <span className='indicator-label'>บันทึกข้อมูล</span>
                            {(formik.isSubmitting ) && (
                            <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                            )}
                        </button>
                    </div>
                    {/* end::Actions */}
                </form>
                {(formik.isSubmitting ) && <RegisterLoading />}
    </>
  )
}

export {RegisterFormContent}
